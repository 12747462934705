<template>
    <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
        <!-- <el-select @clear="clear" clearable v-model="pageInfo.marketPlaceId" style="width：150px;margin-right:10px" placeholder="请选择店铺">
            <el-option-group
            v-for="group in tress"
            :key="group.id"
            :label="group.name">
            <el-option
                v-for="item in group.marketPlaceTree"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                <p @click="getID(group.id,item.id)">{{item.name}}</p>
            </el-option>
        </el-option-group>
        </el-select> -->
        <ShopsAndSites
        v-if="tress.length!=0"
        style="margin-right:10px"
        :list='tress'
        @getShopId='getID'></ShopsAndSites>
        <el-select clearable v-model="pageInfo.status" style="width：150px;margin-right:10px" placeholder="请选择商品状态" @change="find">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in productStatusList"
            :key="item.id"></el-option>
        </el-select>
        <el-select v-model="pageInfo.stockStatus" clearable style="width：150px;margin-right:10px" placeholder="请选择库存状态" @change="find">
            <el-option 
            :label="item.value" 
            :value="item.id"
            v-for="item in stockStatusList"
            :key="item.id"></el-option>
        </el-select>
        <el-input style="width:300px;margin-right:10px" placeholder="请输入ASIN" v-model="keyword" class="input-with-select" @keyup.enter.native = "find">
            <el-select clearable @clear="clearType" style="width:130px" v-model="select" slot="prepend" placeholder="选择输入类型" @change="find">
                <el-option 
                :label="item.value"
                :value="item.id"
                v-for="item in searchList"
                :key="item.id"></el-option>
            </el-select>
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="find">查询</el-button>
        <el-table
        border
        :data='tableData'
        @sort-change="sortTable"
        cell-style="text-align:center"
        header-cell-style="text-align:center;background:#EBEEF5;font-size:14px"
        style="width: 100%;margin-top:20px">
            <el-table-column
                prop="date"
                label="商品信息"
                width="270"
                fixed='left'>
                <template slot-scope="scope">
                    <el-popover
                  placement="right"
                  width="520"
                  trigger="hover"
                  transition="fade-in-linear" 
                  close-delay="30">
                  <div style="display:flex">
                      <div class="pic">
                          <span v-if="!scope.row.imageUrl">暂无图片</span>
                          <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div class="suspend" style="width:590px;font-size:14px">
                          <p class="infoText">{{scope.row.title}}</p>
                          <p style="margin-bottom:10px"><span><img style="width:20px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>子ASIN:{{scope.row.asin}}</span><span>父ASIN:{{scope.row.parentAsin}}</span></p>
                          <p><span>SKU:{{scope.row.commoditySku}}</span><span>品牌:{{scope.row.label}}</span></p>
                      </div>
                  </div>
                  <div class="goodsInfo" slot="reference">
                      <div class="noPic">
                          <span v-if="!scope.row.imageUrl">暂无图片</span>
                          <img v-else :src="scope.row.imageUrl" alt="" width="100%">
                      </div>
                      <div style="text-align:left">
                          <p><img style="width:20px;margin:0 6px 0 0" :src="scope.row.marketPlaceImageUrl" alt=""/>子ASIN:&nbsp;&nbsp;{{scope.row.asin}}</p>
                          <p>SKU:&nbsp;&nbsp;{{scope.row.commoditySku}}</p>
                      </div>
                  </div>
                  </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="statusName" min-width="100">
               <template slot="header">
                 <span>商品状态</span>
                 <el-tooltip effect="dark" placement="top">
                   <div slot="content">
                     <span>商品的销售状态</span><br/>
                     <span>绿色为可售, 紫色为不可售或未完成</span>
                   </div>
                   <span class="el-icon-question"></span>
                 </el-tooltip>
               </template>
            </el-table-column>
            <el-table-column prop="total" min-width="100" sortable='custom'>
               <template slot="header">
                 <span>总计</span>
                 <el-tooltip effect="dark" placement="top" content="FBA/本地库存商品总数">
                   <span class="el-icon-question"></span>
                 </el-tooltip>
               </template>
            </el-table-column>
            <!-- <el-table-column
                prop="sellableQuantity"
                sortable='custom'>
                <template slot="header">
                    <span>可售</span>
                    <span class="el-icon-question"></span>
                </template>
            </el-table-column> -->
            <el-table-column min-width="120" prop="fbasellableQuantity" sortable='custom'>
              <template slot="header">
                <span>FBA库存</span>
                <el-tooltip effect="dark" placement="top" content="亚马逊代发货的商品数量">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="fbmsellableQuantity" sortable='custom'>
              <template slot="header">
                <span>FBM库存</span>
                <el-tooltip effect="dark" placement="top" content="自发货的商品数量">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="localQuantity" sortable='custom'>
              <template slot="header">
                <span>本地库存</span>
                <el-tooltip effect="dark" placement="top" content="ERP系统设置的库存">
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="110" prop="toWarehousedQuantity" sortable='custom'> 
               <template slot="header">
                  <span>待入库</span>
                  <el-tooltip effect="dark" placement="top" content="只在亚马逊后台创建，实际未发货或者只填了单号的商品数量">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
               </template>
            </el-table-column>
            <el-table-column min-width="110" prop="changeWarehouseQuantity" sortable='custom'>
                <template slot="header">
                  <span>转库中</span>
                  <el-tooltip effect="dark" placement="top" content="调拨预留的商品总数">
                    <span class="el-icon-question"></span>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column min-width="110" prop="unsellableQuantity" sortable='custom'>
              <template slot="header">
                <span>不可售</span>
                <el-tooltip effect="dark" placement="top" content="亚马逊仓库中不可售的商品数">
                   <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="100" prop="sellPrice" sortable='custom'>
              <template slot="header">
                  <span>售价</span>
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      <span>商品的Listing价格，不一定是买家购买价格。</span><br/>
                      <span>当商品设置了优惠价时，优惠价才是买家购买的价格。</span><br/>
                      <span>如果商品没有优惠价,</span><br/>
                      <span>那么pending订单的销售额预算，会取此价格进行预算逻辑判断。</span>
                    </div>
                   <span class="el-icon-question"></span>
                  </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{currency}}</span> <span>{{scope.row.sellPrice}}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="100" prop="value" sortable='custom'>
              <template slot="header">
                <span>货值</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content">
                    <span>按照商品Listing售价, 预估出的暂未销售的商品金额总和。</span><br/>
                    <span>计算规则：预估货值 = 总库存 * Listing售价;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
              <template slot-scope="scope">
                <span>{{currency}}</span> <span>{{scope.row.value}}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="110" prop="dailySales" sortable='custom'>
               <template slot="header">
                 <span>日销量</span>
                 <el-tooltip effect="dark" placement="top">
                   <div slot="content">
                     <span>预估日销量，按照设置的预估方式，估算的日销量。</span><br/>  
                     <span>计算规则：近3天销量 * 近3日销量权重占比 + 近7天销量 * 近7日销量权重占比 + 近30天销量 * 近30日销量权重占比;</span><br/>  
                     <span>预设规则：近3天销量 * 70% + 近7天销量 * 20% + 近30天销量 * 10%;</span><br/>  
                     <span>点击下方值进行权重单一或批量设置更新。</span>  
                   </div>
                   <span class="el-icon-question"></span>
                 </el-tooltip>
               </template>
               <template slot-scope="scope">
                 <el-popover
                  placement="top"
                  width="350"
                  trigger="hover"
                  @show='enter(scope.row)'>
                  <div>
                    <el-table
                      :data="weightDay"
                      cell-style="text-align:center;font-size:12px"
                      header-cell-style="text-align:center;font-size:14px"
                      style="width: 100%">
                      <el-table-column
                        prop="SaleDate"
                        label="销售日"
                        width="width">
                        <template slot-scope="line">
                          <span>近{{line.row.SaleDate}}天</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="sales"
                        label="销量"
                        width="width">
                      </el-table-column>
                      <el-table-column
                        prop="SaleDate"
                        label="计算"
                        width="width">
                        <template slot-scope="line">
                          {{line.row.sales + '÷' + line.row.SaleDate}}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="daysales"
                        label="日销"
                        width="width">
                      </el-table-column>
                    </el-table>
                    <p style="font-size:12px;padding:5px 0"><span style="color:red">预计日销量</span>：{{weightDay[0].daysales}} x 0.5 + {{weightDay[1].daysales}} x 0.3 + {{weightDay[2].daysales}} x 0.2 = <span style="color:red">{{(weightDay[0].daysales * 0.5 + weightDay[1].daysales * 0.3 + weightDay[2].daysales * 0.2).toFixed(2) }}</span></p>
                    <p style="font-size:12px;color:#999999">(点击"日销量"可以自定义销量权重)</p>
                  </div>
                  <el-link @click="enter(scope.row,'click')" slot="reference" style="border-bottom:1px dashed #999999;display:inline-block;padding-bottom:3px">{{scope.row.dailySales}}</el-link>
                </el-popover>
               </template>
            </el-table-column>
            <el-table-column min-width="120" prop="canSellDay" sortable='custom'>
              <template slot="header">
                <span>可售天数</span>
                <el-tooltip effect="dark" placement="top">
                  <div slot="content"> 
                    <span>按照预估的日销量，现有的可售总库存，计算的商品预计可售的天数。</span><br/>
                    <span>计算规则 ：可售 / 预估日销量;</span>
                  </div>
                  <span class="el-icon-question"></span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="stockStatusName" sortable='custom'>
                <template slot="header">
                    <span>库存状态</span>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content">
                        <span>基于“可售天数”评估的库存状态。</span><br/>
                        <span>评估规则:</span><br/>
                        <span>黄色表示：可售天数 < 30天，建议尽快安排补货; </span><br/>
                        <span>蓝色表示：60 > 可售天数 ≥ 30，建议密切关注; </span><br/>
                        <span>绿色表示：可售天数 ≥ 60天，表示库存充足; </span><br/>
                        <span>红色表示：滞销（库存大于0，近30天没有销量）; </span><br/>
                        <span>浅灰色表示：已忽略了库存状态检测; </span><br/>
                        <span>灰色表示：库存状态未知; </span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                </template>
                <template slot-scope="scope">
                    <span v-if="scope.row.stockStatusName=='库存充足'" style="color:#13CE66">{{scope.row.stockStatusName}}</span>
                    <span v-else-if="scope.row.stockStatusName=='尽快补货'" style="color:orange">{{scope.row.stockStatusName}}</span>
                    <span v-else-if="scope.row.stockStatusName=='需关注'" style="color:#63B0FF">{{scope.row.stockStatusName}}</span>
                    <span v-else-if="scope.row.stockStatusName=='滞销'" style="color:red">{{scope.row.stockStatusName}}</span>
                    <span v-else>{{scope.row.stockStatusName}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;margin-top:20px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <el-dialog
          title="设置日销量权重"
          :visible.sync="setDayWeight"
          width="30%"
          :before-close="dayWeightClose">
          <div>
            <el-table
              :data="weightDay"
              cell-style="text-align:center;font-size:12px"
              header-cell-style="text-align:center;font-size:14px"
              style="width: 100%">
              <el-table-column
                prop="SaleDate"
                label="销售日"
                width="width">
                <template slot-scope="line">
                  <span>近{{line.row.SaleDate}}天</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="sales"
                label="销量"
                width="width">
              </el-table-column>
              <el-table-column
                prop="daysales"
                label="日销"
                width="width">
              </el-table-column>
              <el-table-column
                prop="daysales"
                label="权重"
                width="width">
                <template slot-scope="line">
                  <el-input v-model="line.row.weight"></el-input>
                </template>
              </el-table-column>
            </el-table>
            <h4 style="text-align:right;padding:10px 6px 0 0">平均日销量: <span style="color:red">{{(weightDay[0].daysales * 0.5 + weightDay[1].daysales * 0.3 + weightDay[2].daysales * 0.2).toFixed(2) }}</span></h4>
          </div>
          <div slot="footer">
            <el-checkbox v-model="allUse" label="1" style="margin-right:10px">全部应用</el-checkbox>
            <el-button type="primary" size="mini" :loading='buttonLoading' @click="changeWeight">立即保存</el-button>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import {listPage, pageInit,detailWeight,addWeight} from '@/api/stockControl.js'
import ShopsAndSites from '@/components/ShopsAndSites.vue'
export default {
    components:{
        ShopsAndSites
    },
    data(){
        return {
          buttonLoading:false,
          allUse:[],
            weightDay:[
              {SaleDate:'3',daysales:'',weight:''},
              {SaleDate:'7',daysales:'',weight:''},
              {SaleDate:'30',daysales:'',weight:''},
            ],
            loading:false,
            setDayWeight:false,
            tableData:[],
            pageInfo:{
                current:'',
                shopId:'',
                marketPlaceId:'',
                status:'',
                stockStatus:'',
                pageSize:'',
                sort:'',
                sortColumn:''
            },
            total:'',
            tress:[],
            productStatusList:[],
            stockStatusList:[],
            searchList:[],
            select:'',
            keyword:'',
            id:'',
            currency:''
        };
    },
    created(){
        this.getPageInit()
        // this.getListPage()
    },
    methods:{
      changeWeight(){
          const num = ((this.weightDay[0].weight)*1000 + (this.weightDay[1].weight)*1000 + (this.weightDay[2].weight)*1000)/1000
          if(num>1){
            this.$message.error('所有权重总和不能大于1')
            return
          }
        this.buttonLoading=true
        addWeight({
          id:this.id,
          code:this.allUse[0]||0,
          threeDaysWeight:this.weightDay[0].weight,
          sevenDaysWeight:this.weightDay[1].weight,
          thrityDaysWeight:this.weightDay[2].weight,
        }).then(res=>{
          this.buttonLoading=false
          console.log(res);
          if(res.data.code==200){
            this.$message.success(res.data.message)
            this.dayWeightClose()
            this.getListPage()
          }else {
            this.$message.error(res.data.message)
          }
        })
      },
        enter(row,info){
          this.id=row.id
          if(info=='click'){
            this.loading=true
            detailWeight(row.id).then(res=>{
              if(res.data.code==200){
                this.setDayWeight=true
                this.loading=false
                this.weightDay[0].weight=res.data.data.threeDaysWeight
                this.weightDay[1].weight=res.data.data.sevenDaysWeight
                this.weightDay[2].weight=res.data.data.thrityDaysWeight
              }else{
                this.$message.error(res.data.message)
                this.buttonLoading=false
              }
              console.log(res);
            })
          }
          this.weightDay[0].sales=row.threeDaysSales
          this.weightDay[1].sales=row.sevenDaysSales
          this.weightDay[2].sales=row.thrityDaysSales
          this.weightDay[0].daysales=(row.threeDaysSales/this.weightDay[0].SaleDate).toFixed(2)
          this.weightDay[1].daysales=(row.sevenDaysSales/this.weightDay[1].SaleDate).toFixed(2)
          this.weightDay[2].daysales=(row.thrityDaysSales/this.weightDay[2].SaleDate).toFixed(2)
          
          console.log(this.weightDay);
        },
        dayWeightClose(){
            this.allUse=[]
            this.weightDay=[
              {SaleDate:'3',daysales:'',weight:''},
              {SaleDate:'7',daysales:'',weight:''},
              {SaleDate:'30',daysales:'',weight:''},
            ]
            this.setDayWeight=false
        },
        clearType(){
            this.select=''
            this.keyword=''
        },
        getListPage(){
            let sku = ''
            // if(this.select==''||this.keyword==''){
            //     sku=null
            // }else if(this.select=='asin'){
            //     sku={asin:this.keyword}
            // }else if(this.select=='parent_asin'){
            //     sku={parent_asin:this.keyword}
            // }else if(this.select=='commodity_sku'){
            //     sku={commodity_sku:this.keyword}
            // }
            this.loading=true
            listPage({[this.select]:this.keyword?this.keyword:null,...this.pageInfo}).then(res=>{
                this.loading=false
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
            }).catch(()=>{
                this.loading=false
            })
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        getPageInit(){
            pageInit().then(res=>{
                this.tress=res.data.data.tress
                this.productStatusList=res.data.data.productStatusList
                this.stockStatusList=res.data.data.stockStatusList
                this.searchList=res.data.data.searchList
            })
        },
        getID(shopID,marketPlaceId,name,currency){
            this.pageInfo.shopId=shopID
            this.pageInfo.marketPlaceId=marketPlaceId
            this.currency=currency
            this.getListPage()
        },
        find(){
            this.pageInfo.current=1
            this.getListPage()
        },
        clear(){
            this.pageInfo.shopId=''
            this.pageInfo.marketPlaceId=''
        },
        sortTable(column){
            console.log(column);
            if(column.order=='ascending'){
                this.pageInfo.sort='asc'
            }else if(column.order=='descending'){
                this.pageInfo.sort='desc'
            }else if(column.order==null){
                this.pageInfo.sort=''
                this.pageInfo.sortColumn=''
                this.getListPage()
                return
            }
            this.pageInfo.sortColumn=column.prop
            this.getListPage()
        }
    }
}
</script>

<style scoped lang="scss">
.noPic{
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size:8px;
    color: #909399;
    margin-right: 15px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.goodsInfo{
    display: flex;
    >div:nth-of-type(2){
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    p{
        font-size: 12px;
        color: #909399;
    }
}
.pic{
    width:160px;
    height: 160px;
    font-size:8px;
    color: #909399;
    text-align: center;
    line-height: 160px;
    margin-right: 10px;
    span{
        display: block;
        background: #EBEEF5;
        width: 100%;
        height: 100%;
    }
}
.infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
}
.suspend{
    p{
        span{
            display: inline-block;
            width: 180px;
            margin-right: 10px;
            font-size: 13px;
        }
    }
}
img{
    vertical-align: middle;
}
</style>